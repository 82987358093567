import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import {config} from '../../config/config.js';
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";

// axios.get("/base/deals/",
//   {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}})
// .then((res) => console.log(res.data))
//       .catch((err) => console.log(err));

// getEvents(){
//         axios
//             .get("http://127.0.0.1:8000/api/getevents")
//             .then(response =>{
//                 this.state2.events = response.data;
//             }) 
//             .catch(err => console.log(err));
//     };




export default function ProgressPage() {
  const { id } = useParams()
  const navigate = useNavigate();

   const [deal, setDeal] = useState([]);

   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

   const handleInput = (e) => {


   		if(e.key=='Tab' || e.key=='Enter') {
   			let value=parseFloat(e.target.value.replace(",", '.'))
   			let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(",", '.'))

   			if(value>max ) {
	   				e.target.parentNode.classList.add("error");
	   		} else if(value>0) {
   				e.target.disabled = true;
		   		e.target.parentNode.classList.add("saved");
		   		e.target.parentNode.classList.remove("error");
		   		e.target.value=value.toString().replace(".", ',')

		   		let estimate=parseInt(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("estimate_id"))
		   		let work=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("work_id"))
		   		 axiosInstance.post('/base/report/', {'estimate':estimate, 'work':work, 'workload':value}).then(function (response) {
	                if(response.status === 200){
	                  console.log('save report')
	                }
	            })
	            .catch(function (error) {
	              console.log(error)
	            });

   			} else {
   				e.target.value=''
   			}
   		} 
   		
   		// e.target.previousSibling.disabled = true;
   		// e.target.style.display = 'none';
   		// e.target.nextSibling.style.display = 'inline-block';
   		// e.target.previousSibling.parentNode.classList.add("saved");
   }

   const handleSave = (e) => {

   		let value=parseFloat(e.target.previousSibling.value.replace(",", '.'))

   		let max=parseFloat(e.target.parentNode.parentNode.nextSibling.innerText.replace(",", '.'))

   		if(value>max ) {
   				e.target.parentNode.classList.add("error");
   		} else if(value>0) {
	   		e.target.previousSibling.disabled = true;
	   		e.target.parentNode.classList.add("saved");
	   		e.target.parentNode.classList.remove("error");
	   		e.target.previousSibling.value=value.toString().replace(".", ',')


	   		let estimate=parseInt(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("estimate_id"))
	   		let work=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("work_id"))
	   		 axiosInstance.post('/base/report/', {'estimate':estimate, 'work':work, 'workload':value}).then(function (response) {
                if(response.status === 200){
                  console.log('save report')
                }
            })
            .catch(function (error) {
              console.log(error)
            });

	   	} else {
   			e.target.previousSibling.value=''
   		}
   }

   const handleEdit = (e) => {
   		e.target.previousSibling.previousSibling.disabled = false;
   		e.target.previousSibling.previousSibling.focus();
   		e.target.parentNode.classList.remove("saved");

   		let estimate=parseInt(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("estimate_id"))
	   	let work=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("work_id"))
   		axiosInstance.post('/base/report/', {'estimate':estimate, 'work':work, 'workload':0}).then(function (response) {
          if(response.status === 200){
            console.log('remove report')
          }
      })
      .catch(function (error) {
        console.log(error)
      });

   }

   const handleCopy = (e) => {
   	let remainder=e.target.parentNode.nextSibling
   	let input=e.target.parentNode.previousSibling.firstChild
   	input.value=remainder.innerText
   		
   }
   const handleWorkload = (e) => {

   	let els = e.target.previousSibling.getElementsByTagName('input')

   	let text='';
		for (var i = 0; i < els.length; i++) {
			console.log(els[i].parentElement.parentElement.firstChild.innerText);

			if(els[i].value) text=text+' '+els[i].parentElement.parentElement.firstChild.innerText+' ('+els[i].value+')';


		}

		alert('Отправка объёмов на согласование: '+text);
  // alert(els[i].value);
	// console.log(els[i].value)
   // const inputs=e.target.previousSiblingg.getElementsByTagName('input');
  // 	inputs.map(name => (  
   //        <li>  
   //          {name}  
   //        </li>  
   //      ))

   	/*
   	e.target.previousSiblingg.getElementsByTagName('input')
  .forEach((input) => {
    console.log(input.value);
  });*/
   	// let inputs=e.target.previousSibling.getElementsByTagName('input')
    // [...inputs].forEach(child => {
		//   console.log(child);
		// });

    }
  useEffect(() => {
	setisLoading(true);


   axiosInstance.get(`/base/admin/deal/${id}`)
	.then(function(response){
	  

		// var money_array={}
		 /* response.data.estimates.forEach(function(estimate) {
	
			config.currency.forEach(function(m) {
				if(m in estimate.money) {
					if (m in money_array) 
						money_array[m]=estimate.money[m].cost_total+money_array[m]
				 	else
						money_array[m]=estimate.money[m].cost_total
				}
				
			})
		});
		  console.log(money_array);*/
		//response.data['total']=money_array

		// money_array['USD']=4685600.51

		// console.log('tot', response.data)
		// response.data['total']=money_array;
 
		// console.log(response.data)
		// console.log(response.data)

	  setDeal(response.data)
	  setisLoading(false)
	  // console.log(deal.acts);
	})
	.catch((err) => {
		navigate('/404')
		setisError(true);
		setisLoading(false);
	  });
  }, [])




  return (  
  	<>
  	{deal.id?<Helmet><title>Дашбоард {deal.name} от {new Date(deal.date).toLocaleDateString()}</title></Helmet>:null}
  	
  	<Menu/>
    <div id="page">
    <TopBar/>
	<div className="container">
	  <div className="header">Дашбоард</div>
	 

	  <div className="card_row">
	  	<div className="card">
	  		<div className="card_header">Договор</div>
	  			{deal.name?
	  			<>
	  			<div className="card_header2">{deal.name}</div>
	  			<div className="card_date">{new Date(deal.date).toLocaleDateString()}</div>
	  			</>:null}

	  			<div className="card_header">Субподрядчик</div>

	  			{deal.sub_id?
	  			<Link  to={`/admin/sub/${deal.sub_id}/`}>
	  			<div className="card_header2">{deal.sub_name}</div>
	  			<div className="card_date">{deal.sub_inn}</div>
	  			</Link>:null}



	  	</div>
	  	<div className="card">
	  		<div className="card_header">Информация</div>

	  		<div className="card_table">
		  		<div className="card_attr">Код</div>
		  		<div className="card_value">{deal.code}</div>
		  	</div>

		  	{deal.executor?
	  		<div className="card_table">
		  		<div className="card_attr">Исполнитель</div>
		  		<div className="card_value">{deal.executor}</div>
		  	</div>
	  		:null}
	  		
	  		{deal.project_id?
	  		<div className="card_table">
	  			<div className="card_attr">Проект</div>
					<div className="card_value"><Link to={`/admin/project/${deal.project_id}/`}>{deal.project_name}</Link></div>
				</div>
	  		:null}
	  		
	  		{deal.object_id?
	  		<div className="card_table">
		  		<div className="card_attr">Объект</div>
		  		<div className="card_value"><Link  to={`/admin/object/${deal.object_id}/`}>{deal.object_name}</Link></div>
		  	</div>
	  		:null}
	  		
	  		

				
	  	</div>
	  	<div className="card">
	  	<div className="card_table">
	  			<div className="card_table_cell">
	  				<div className="card_header">Прогресс</div>
					    <svg viewBox="0 0 36 36" className="circular-chart">
					      <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
					      <path className="circle-send" strokeDasharray={(deal.progress_report+deal.progress)+', 100'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
					      <path className="circle" strokeDasharray={deal.progress+', 100'} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
					      <text x="18" y="20.35" className="percentage">{deal.progress}%</text>
					      <text x="18" y="28" className="percentage_report">{deal.progress_report}%</text>
					    </svg>
		  		</div>
		  
	  		</div>
	  		 {deal && deal.total && Object.keys(deal.total).length>0?
	  		<div className="table total_money">
				  	<div className="table_header">
                <div className="table_cell table_right">
                  В сметах
                </div>
                <div className="table_cell table_right">
                  В КС-2
                </div>
                <div className="table_cell table_right">
                  На рассмотрении
                </div>
                <div className="table_cell table_right">
                  Остаток
                </div>
              </div>
             {Object.keys(deal.total).map((currency, index) => (

              <div className="table_row">
              	<div className="table_cell table_right">
                     {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.total[currency])} {currency!=='null'?config.currency_code[currency].code:null}
                </div>
                <div className="table_cell table_right">
                     {deal.total_act[currency]?<>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.total_act[currency])} {currency!=='null'?config.currency_code[currency].code:null}</>:null} 
                </div>
                <div className="table_cell table_right">
                     {deal.total_report[currency]?<>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.total_report[currency])} {currency!=='null'?config.currency_code[currency].code:null}</>:null}
                </div>
                <div className="table_cell table_right">
                     {deal.total_remainder[currency]?<>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.total_remainder[currency])} {currency!=='null'?config.currency_code[currency].code:null}</>:null}
                </div>
              </div>
            
              ))}
				  </div>:null}
	  		
	  	</div>
	  </div>
				
	  <div className="block">
          <div className="block_header">Список смет</div>

          <div className="table">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер
                </div>
                <div className="table_cell" style={{width:'250px'}}>
                  КС-2
                </div>
                <div className="table_cell">
                  Количество работ
                </div>
                <div className="table_cell table_right table_cost">
                  Стоимость материалов
                </div>
                <div className="table_cell table_right table_cost">
                  Цена работ
                </div>
                <div className="table_cell table_right table_cost">
                  Итого
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {deal.estimates_order.length?
                <>
                {deal.estimates_order.length && deal.estimates_order.map((estimate) => (
      
                <div className="table_row" key={deal.estimates[estimate].id} relevance={deal.estimates[estimate].relevance?'true':'false'}>
                    <div className="table_cell">
		                   <Link to={`/admin/estimate/${estimate}/`}>
		                    <div class="link">{deal.estimates[estimate].number}</div>
		                    <div class="small_info">{new Date(deal.estimates[estimate].date).toLocaleDateString()}</div>
		                  </Link>
                    </div>
                    <div className="table_cell">
                     {deal.acts_order.length>0 && deal.acts_order.map((act) => (
                     		<>
                     		{deal.acts[act].estimate==estimate?
                     		<Link to={`/admin/act/${act}/`} relevance={deal.acts[act].conducted?'true':'false'}>
			                    <div class="link">{deal.acts[act].number}<div class="small_right">от {new Date(deal.acts[act].date).toLocaleDateString()}</div></div>
			                  </Link>
			                  :null}
			                  </>
                     	))}
                    </div>
                    <div className="table_cell">
                    	{deal.estimates[estimate].works_count}
                     
                    </div>
                   <div className="table_cell table_right">
                    	{deal.estimates[estimate].money && Object.keys(deal.estimates[estimate].money).map((currency) => (  
	                   		<div>
		                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.estimates[estimate].money[currency].cost_materials)} {currency!=='null'?config.currency_code[currency].code:null}
		                    </div>
											))}
                    </div>
                    <div className="table_cell table_right">
                    	{deal.estimates[estimate].money && Object.keys(deal.estimates[estimate].money).map((currency) => (  
	                   		<div>
		                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.estimates[estimate].money[currency].cost_installation)} {currency!=='null'?config.currency_code[currency].code:null}
		                    </div>
											))}
                    </div>
                    <div className="table_cell table_right">
                     {deal.estimates[estimate].money && Object.keys(deal.estimates[estimate].money).map((currency) => (  
	                   		<div>
		                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.estimates[estimate].money[currency].cost_total)} {currency!=='null'?config.currency_code[currency].code:null}
		                    </div>
											))}
                    </div>
                   
                    
                    
                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Сметы отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
    		<div className="block">
          <div className="block_header">Список КС-2</div>

          <div className="table">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер
                </div>
                 <div className="table_cell" style={{width:'250px'}}>
                  Смета
                </div>
                <div className="table_cell">
                  Количество работ
                </div>
                <div className="table_cell table_right table_cost">
                  Стоимость материалов
                </div>
                <div className="table_cell table_right table_cost">
                  Цена работ
                </div>
                <div className="table_cell table_right table_cost">
                  Итого
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {deal.acts_order.length?
                <>
                {deal.acts_order.length && deal.acts_order.map((act) => (
      
                <div className="table_row" key={deal.acts[act].id} relevance={deal.acts[act].conducted?'true':'false'}>
                    <div className="table_cell">
                      <Link to={`/admin/act/${act}/`}>
		                    <div class="link">{deal.acts[act].number}</div>
		                    <div class="small_info">{new Date(deal.acts[act].date).toLocaleDateString()}</div>
		                  </Link>
                    </div>
                    <div className="table_cell">
                      <Link to={`/admin/estimate/${deal.acts[act].estimate}/`}>
		                    <div class="link">{deal.estimates[deal.acts[act].estimate].number}</div>
		                    <div class="small_info">{new Date(deal.estimates[deal.acts[act].estimate].date).toLocaleDateString()}</div>
		                  </Link>
                    </div>
                    <div className="table_cell">
                    	{deal.acts[act].works_count}
                     
                    </div>

                   {deal.acts[act].money && Object.keys(deal.acts[act].money).map((currency) => (  
                   	<>
                   		<div className="table_cell table_right">
	                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.acts[act].money[currency].cost_materials)} {currency!=='null'?config.currency_code[currency].code:null}
	                    </div>
	                    <div className="table_cell table_right">
	                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.acts[act].money[currency].cost_installation)} {currency!=='null'?config.currency_code[currency].code:null}
	                    </div>
										  <div className="table_cell table_right">
	                      {Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(deal.acts[act].money[currency].cost_total)} {currency!=='null'?config.currency_code[currency].code:null}
	                    </div>
	                    </>
										))}
                   
                    
                    
                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">КС-2 отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
      	<div className="block">
          <div className="block_header">Список отчётов о выполнении</div>

          <div className="table">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер
                </div>
                 <div className="table_cell" style={{width:'250px'}}>
                  Смета
                </div>
                <div className="table_cell">
                  Период
                </div>
                <div className="table_cell">
                  Количество работ
                </div>
                <div className="table_cell">
                  Статус
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {deal.reports_order.length?
                <>
                {deal.reports_order.length && deal.reports_order.map((report) => (
      
                <div className="table_row" key={deal.reports[report].id}>
                    <div className="table_cell">
                        <Link to={`/admin/report/${deal.reports[report].id}/`}>
			                    <div class="link">{deal.reports[report].id}</div>
			                    <div class="small_info">{new Date(deal.reports[report].date).toLocaleDateString()}</div>
			                  </Link>
                      </div>
                      <div className="table_cell">
                      	<Link to={`/admin/estimate/${deal.estimates[deal.reports[report].estimate].id}/`}>
			                    <div class="link">{deal.estimates[deal.reports[report].estimate].number}</div>
			                    <div class="small_info">{new Date(deal.estimates[deal.reports[report].estimate].date).toLocaleDateString()}</div>
			                  </Link>
                      </div>
                      <div className="table_cell">
                        {new Date(deal.reports[report].date_from).toLocaleDateString()} - {new Date(deal.reports[report].date_to).toLocaleDateString()}
                      </div>
                      <div className="table_cell">
                        {deal.reports[report].works}
                      </div>
                      <div className="table_cell">
                        {deal.reports[report].export?
                          <div class="accept">Отправлено в 1С</div>
                          :

                          <>
                          {deal.reports[report].back?<div class="back">Возвращён на доработку</div>:null}
                          {deal.reports[report].accept?<div class="accept">Принят</div>:null}
	                        {deal.reports[report].reject?<div class="reject">Отклонён</div>:null}
	                        {!deal.reports[report].back&&!deal.reports[report].accept&&!deal.reports[report].reject?<div className="wait">Ожидает рассмотрения</div>:null}
                          </>}
                        
                      </div>

                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Отчёты отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>





	
	</div>
	</div>

	</>
  );

}

