import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import more_svg from '../../assets/img/more.svg';
import close_svg from '../../assets/img/close.svg';
import ReactPaginate from 'react-paginate';

export default function SubsPage() {
  const { hided } = useParams()
  const navigate = useNavigate();

   const [subs, setSubs] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isModalLoading, setisModalLoading]=useState(false);
   const [isError, setisError] = useState(false);

   const handleModalClose = (e) => {
    document.body.classList.remove("modal-open")
   }

   const handleHide = (e) => {
      
      let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
      axiosInstance.post('/sub/edit/', {'sub_id':sub_id, 'hide': true}).then(function (response) {
                if(response.status === 200){
                   const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {

                          sub.hide=true
                          document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
                        }
                    });

                    setSubs(newSubs)

                }
        })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
        });
    }
  const handleShow = (e) => {
      
      let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
      axiosInstance.post('/sub/edit/', {'sub_id':sub_id, 'hide': false}).then(function (response) {
                if(response.status === 200){
                   const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {

                          sub.hide=false
                          document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
                        }
                    });

                    setSubs(newSubs)

                }
        })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
        });
    }
   const handleDisable = (e) => {
    

    // setSubs(arr)
    // const subs_copy = [... subs]
    //     console.log(subs_copy)
        // setSubs(subs_copy)    
  


    let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
      axiosInstance.post('/sub/edit/', {'sub_id':sub_id, 'active': false}).then(function (response) {
                if(response.status === 200){
                  const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {

                          sub.user_active=false

                        }
                    });

                    setSubs(newSubs)

                   
                  document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
                }
        })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
        });
  }
    const handleActive = (e) => {
      
      let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
      axiosInstance.post('/sub/edit/', {'sub_id':sub_id, 'active': true}).then(function (response) {
                if(response.status === 200){
                   const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {

                          sub.user_active=true

                        }
                    });

                    setSubs(newSubs)

                  document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
                }
        })
            .catch(function (error) {
              alert(error)
              setisLoading(false);
        });
    }





    const handleModalAdd = (e) => {
      document.querySelector("#modal_header span").innerText='Добавить Email';
      document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
      document.body.classList.add("modal-open")

      let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))

      document.querySelector("#modal_block #sub_id").value=sub_id
      document.querySelector("#modal_block #email").value=''


      /*
      <div class="setting_info">Email:</div><div class="setting_value"><input id="date_from" type="date" min="2000-01-01" max="2024-07-29" value="2024-07-22"></div>


      let input=e.target.previousSibling
      let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
      axiosInstance.post('/sub/add/', {'sub_id':sub_id, 'email': input.value}).then(function (response) {
                if(response.status === 200){
                  
                }
        })
            .catch(function (error) {
              alert(error.response.data.error)
              // alert(error)
              setisLoading(false);
        });*/
    }
    const handleAdd = (e) => {
      let input=document.querySelector("#modal_block #email")
      let sub_id=parseInt(document.querySelector("#modal_block #sub_id").value)
      setisModalLoading(true);
      axiosInstance.post('/sub/add/', {'sub_id':sub_id, 'email': input.value}).then(function (response) {
                if(response.status === 200){
                    setisModalLoading(false);
                    document.body.classList.remove("modal-open")


                    const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {
                         
                          sub.user_email= input.value
                          sub.user_active=true
                          
                        }
                    });

                    setSubs(newSubs)
                }
        })
            .catch(function (error) {
              alert(error)
              // alert(error)
              setisModalLoading(false);
        });
    }

    const handleRemove = (e) => {
      if(window.confirm(`Вы хотите удалить Email?`)) {
        let sub_id=parseInt(e.target.parentNode.parentNode.parentNode.getAttribute("sub_id"))
        axiosInstance.post('/sub/remove/', {'sub_id':sub_id}).then(function (response) {
                if(response.status === 200){
                  document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
           

                  const newSubs = {...subs};

                    newSubs.results.forEach(sub => {
                        
                        if(sub.id===sub_id) {
                         
                          sub.user_email= false
                          sub.user_active=false
                          sub.user_last_login=false
               
                        }
                    });

                    setSubs(newSubs)

                }
        })
            .catch(function (error) {
              alert(error.response.data.error)
              // alert(error)
              setisLoading(false);
        });
      }
    }
     const handleButtons = (e) => {
      
      let block=e.target.parentNode.nextSibling
      if(block.classList.contains("active")) {
        document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
      } else {
        document.body.querySelectorAll('.profile_buttons.active').forEach(i => i.classList.remove('active'))
        block.classList.add("active")
      }
      
      
      //console.log();
    }

  useEffect(() => {
   let sub_link=''
    if(hided) sub_link='?hided=true'
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/sub/all/"+sub_link)
    .then(function(response){
      
      // console.log(response.data);
      setSubs(response.data)
      setisLoading(false)
    })
    .catch((err) => {

        setisError(true);
        setisLoading(false);
      });
  }, [])

 
  const handlePageClick = (e) => {
   var sub_link=''
    if(hided) sub_link='&hided=true'

    setisLoading(true)

   axiosInstance.get("/sub/all/?page="+(e.selected+1)+sub_link)
    .then(function(response){
      
      // console.log(response.data);
      setSubs(response.data)
      setisLoading(false)
    })
    .catch((err) => {

        setisError(true);
        setisLoading(false);
      });
  }

  // const [post, setPost] = React.useState(null);

  // React.useEffect(() => {
  //   axios.get("/base/deals/", {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}}).then((response) => {
  //     setPost(response.data);
  //     console.log(response.data)
  //   });
  // }, []);

  

  return (  
    <>
    <div id="modal">
      <div id="modal_block">
        <div id="modal_top">
          <div id="modal_header">
            <span></span>
            <div id="modal_close" onClick={handleModalClose}><img src={close_svg}/></div>
          </div>
          
        </div>

        <div id="modal_body">
          {isModalLoading?
            <div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div> 
            :<>
              <input type="hidden" id="sub_id"/>
          <div className="setting_info">Email</div>
          <div className="setting_value">
            <input type="text" id="email"/>
          </div>
            </>
          }
          
        </div>
        {isModalLoading==false?
        <div id="modal_bottom">
            <button onClick={handleAdd}>Добавить</button>
        </div>
        :null}
      </div>
    </div>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">{!hided?"Субподрядчики":"Скрытые субподрядчики"}</div>
       <div className="block">
      

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell">
                  Субподрядчик
                </div> 
                <div className="table_cell">
                  Аккаунт
                </div>
                <div className="table_cell">
                  Статус
                </div> 
                <div className="table_cell">
                  Последний вход
                </div> 
                <div className="table_cell table_more">
                  
                </div> 
              </div>

      
      {isLoading? 
                <>
                {[...Array(10).keys()].map((load) => (
                <div key={load} className="table_row">
                  {[...Array(5).keys()].map((l) => (<div key={l} className="table_cell"><div className="text_placeholder"></div></div>))}
                  </div>
                ))}
                </>
              :
                <>
                {subs.count>0?
                <>
      {subs.count>0 && subs.results.map((sub) => ( 
            <div className="table_row" sub_id={sub.id} key={sub.id} hide={sub.hide?'true':'false'}>
                <div className="table_cell">
                  <Link to={`/admin/sub/${sub.id}/`}>
                    <div className="link">{sub.name} {sub.hide?'(СКРЫТ)':null}</div>
                    <div className="small_info">{sub.inn}</div>
                  </Link>
                </div>
                <div className="table_cell">
                    {sub.user_email?sub.user_email:null}
  
                </div>
                <div className="table_cell">
                  {sub.user_email?
                  <>
                  {sub.user_active?
                      <div className="accept">Активен</div>
                      :
                      <div className="reject">Отключен</div>
                  }
                  
                  
                  
                  </>:null
                  }
                </div>
                <div className="table_cell">
                  {(sub.user_email && sub.user_last_login)?
                  new Date(sub.user_last_login).toLocaleDateString('ru')+' в '+new Date(sub.user_last_login).toLocaleTimeString('ru'):
                  <>{sub.user_email && sub.user_is_approve==false?
                      <>Почта не подтверждена</>:null
                  }</>
                  }
                </div>
                <div className="table_cell table_right">
                  <div className="profile_more" onClick={handleButtons}><img src={more_svg}/></div>
                  <div className="profile_buttons">
                    
                    

                    {sub.hide?
                    <div className="profile_button" onClick={handleShow}>Показывать на портале</div>
                    :
                    <>{sub.user_email?
                      <>
                        
                        {sub.user_active?
                          <div className="profile_button" onClick={handleDisable}>Отключить доступ</div>
                        :
                          <div className="profile_button" onClick={handleActive}>Активировать доступ</div>
                        }
                        <div className="profile_button remove" onClick={handleRemove}>Удалить Email</div>
                      </>:
                      <div className="profile_button" onClick={handleModalAdd}>Добавить аккаунт</div>
                    }
                    <div className="profile_button" onClick={handleHide}>Скрыть с портала</div>
                    </>}
                  </div>
                </div>
              </div>

          ))}
      </>
                  :
                  <div className="table_row"><div className="table_cell">Субподрядчики отсутствуют</div></div>
                }
      </>}
    
        </div>
      </div>
       {!isLoading && subs.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={subs.page-1}
        pageCount={subs.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}

      <br/>
      <ul role="navigation"><li>
      {!hided?
      <Link to={`/admin/subs/hided/`}><div className="link">Скрытые субподрядчики</div></Link>
      :<Link to={`/admin/subs/`}><div className="link">Видимые субподрядчики</div></Link>}
      </li></ul>

      

    </div>
    </div>
    </>
  );

}

